<template>
    <div class="detail-image-box">
        <!-- 中图片 -->
        <div class="detail-image-md-box">
            <el-image
                :src="mdImg"
                fit="scale-down"
                lazy
                class="detail-image-md">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>
        <!-- 小图片列表区域 -->
        <div class="detail-image-sm-box">
            <!-- 左右按钮  btn-disable-->
            <a href="javascript:;"
                :class="[
                    'detail-image-btn-prev',
                    {'btn-disable': list_left == 0}
                ]"
                @click="handlePrev">
                <i class="el-icon-arrow-left"></i>
            </a>
            <a href="javascript:;"
                :class="[
                    'detail-image-btn-next',
                    {'btn-disable': list_left + list_width <= box_width}
                ]"
                @click="handleNext">
                <i class="el-icon-arrow-right"></i>
            </a>
            <!-- 小图片列表盒子 -->
            <div class="detail-image-sm-list-box" ref="smList">
                <ul class="detail-image-sm-list"
                    :style="{width:list_width+'px', left:list_left+'px'}">
                    <li v-for="(item,index) in imgList"
                        :key="index"
                        @click="handleChange(item,index)">
                        <el-image
                            :src="item"
                            fit="scale-down"
                            lazy
                            class="detail-image-sm">
                            <!-- 加载中样式 -->
                            <template v-slot:placeholder>
                                <div class="image-slot">
                                    <i class="el-icon-loading"></i>
                                </div>
                            </template>
                            <!-- 加载失败样式 -->
                            <template v-slot:error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        mdImg: String,
        imgList: Array
    },
    data() {
        return {
            top: "",
            left: "",
            list_left: 0,
            box_width: 0
        }
    },
    computed: {
        list_width(){
            if(this.imgList && this.imgList.length){
                return this.imgList.length*90;
            }else{
                return 0
            }
        }
    },
    mounted() {
        this.box_width = this.$refs.smList.clientWidth;
    },
    methods: {
        // 上一个按钮
        handlePrev(){
            let lf = this.list_left;
            if(lf < 0){
                lf += 90;
            }
            this.list_left = lf;
        },
        // 下一个按钮
        handleNext(){
            let lf = this.list_left, wt = this.list_width, bw = this.box_width;
            if(lf + wt >=bw){
                lf -= 90;
            }
            this.list_left = lf;
        },
        // 切换图片
        handleChange(md,index){
            this.$emit('change-md', md, index);
        }
    }
}
</script>

<style scoped>
/* 中图片 */
.detail-image-md-box{
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
}
.detail-image-md-box::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.detail-image-md{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* 左右按钮 */
.detail-image-btn-prev,
.detail-image-btn-next{
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 80px;
    line-height: 90px;
    text-align: center;
    font-weight: bold;
    color: #8d9afc;
}
.detail-image-btn-prev{
    float: left;
}
.detail-image-btn-next{
    float: right;
}
.detail-image-btn-prev>i,
.detail-image-btn-next>i{
    font-size: 25px;
    font-weight: bold;
}
/* 禁用按钮样式 */
.detail-image-sm-box>a.btn-disable{
    cursor: not-allowed;
}
.detail-image-sm-box>a.btn-disable>i{
    color: #909399;
}

/* 小图片列表盒子 */
.detail-image-sm-list-box{
    position: relative;
    height: 80px;
    margin: 0 30px;
    overflow: hidden;
}
/* 小图片列表 */
.detail-image-sm-list{
    position: absolute;
    top: 0;
    left: 0;
    transition: all linear .5s;
}
.detail-image-sm-list>li{
    float: left;
    width: 80px;
    height: 80px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
}
.detail-image-sm{
    width: 100%;
    height: 100%;
}
</style>